import { datadogRum } from "@datadog/browser-rum";
import {
  OIDCInteractionResponse,
  OIDCInteractionResponseSchema,
  OIDCPostInteractionErrorSchema,
  OIDCPostInteractionResponse,
  OIDCPostInteractionResponseSchema,
} from "@frec-js/common";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "node:querystring";
import { useEffect, useMemo, useState } from "react";
import useFetch, { FetchResult } from "react-fetch-hook";
import url from "url";

import { useFirebaseAuthCheck } from "../../hooks/useFirebaseAuth";

export const formatPath = (
  pathname: string,
  searchParams?: ParsedUrlQueryInput,
) => {
  const host = process.env.NEXT_PUBLIC_OIDC_HOST ?? undefined;
  return url.format({
    host,
    pathname,
    query: searchParams,
  });
};

export type OIDCQueryParams = {
  client_id: string;
  scope: string;
  response_type: string;
  redirect_uri: string;
  code_challenge: string;
  code_challenge_method: string;
  prompt: string;
  state: string;
  // Plaid specific
  recipient_id: string | undefined;

  // Yodlee specific
  yodlee_appid: string | undefined;
  yodlee_appname: string | undefined;
  yodlee_logo_url: string | undefined;
};

export const useOIDCQueryParams = (): OIDCQueryParams | undefined => {
  const { query: searchParams } = useRouter();

  const [oauthQueryParams, setOIDCQueryParams] = useState<
    undefined | OIDCQueryParams
  >();

  useEffect(() => {
    const client_id = stringOrFirst(searchParams["client_id"]);
    const scope = stringOrFirst(searchParams["scope"]);
    const response_type = stringOrFirst(searchParams["response_type"]);
    const redirect_uri = stringOrFirst(searchParams["redirect_uri"]);
    const code_challenge = stringOrFirst(searchParams["code_challenge"]);
    const state = stringOrFirst(searchParams["state"]);
    const code_challenge_method = stringOrFirst(
      searchParams["code_challenge_method"],
    );
    const recipient_id = stringOrFirst(searchParams["recipient_id"]);
    const yodlee_appid = stringOrFirst(searchParams["appid"]);
    const yodlee_appname = stringOrFirst(searchParams["appname"]);
    const yodlee_logo_url = stringOrFirst(searchParams["logo_url"]);

    const prompt = stringOrFirst(searchParams["prompt"]) ?? "login";

    // TODO: Plaid does not send a consent prompt, but our OIDC provider requires it for refresh tokens
    const promptWithConsent = prompt && addConsentPrompt(prompt);

    if (
      client_id &&
      scope &&
      response_type &&
      redirect_uri &&
      code_challenge &&
      code_challenge_method &&
      promptWithConsent &&
      state
    ) {
      const newVal = {
        client_id,
        scope,
        response_type,
        redirect_uri,
        code_challenge,
        code_challenge_method,
        prompt: promptWithConsent,
        state,
        recipient_id: recipient_id ?? undefined,
        yodlee_appid: yodlee_appid ?? undefined,
        yodlee_appname: yodlee_appname ?? undefined,
        yodlee_logo_url: yodlee_logo_url ?? undefined,
      };

      setOIDCQueryParams((prev) =>
        String(prev) !== String(newVal) ? newVal : prev,
      );
    }
  }, [searchParams]);

  return oauthQueryParams;
};

const addConsentPrompt = (prompt: string): string => {
  const decoded = decodeURIComponent(prompt);
  if (decoded.split(" ").includes("consent")) {
    return decoded;
  }
  return `${decoded} consent`;
};

export const stringOrFirst = (
  v: string | string[] | undefined,
): string | undefined => {
  if (typeof v === "string") {
    return v;
  } else if (Array.isArray(v)) {
    return v.reverse().pop();
  }
};

export type UseSubmitLoginPromptProps = {
  interactionId?: string;
  skip?: boolean;
};
export const useSubmitLoginPrompt = ({
  interactionId,
  skip,
}: UseSubmitLoginPromptProps) => {
  const { token, logOut } = useFirebaseAuthCheck();
  const fetch = useFetch(
    formatPath(`/oidc/interaction/${interactionId}/login`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ?? "",
      },
      credentials: "include",
      body: JSON.stringify({}),
      depends: [!!token, !!interactionId, !skip],
      formatter: async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return OIDCPostInteractionResponseSchema.parse(data);
        } else {
          const safeParse = OIDCPostInteractionErrorSchema.safeParse(data);
          if (
            safeParse.success &&
            (safeParse.data.message === "MFA required" ||
              safeParse.data.message === "Unauthorized Access")
          ) {
            // If we got an auth related error, logout will cause everything to restart
            // again
            await logOut();
          }
        }
      },
    },
  );

  if (fetch.error) {
    throw fetch.error.cause;
  }

  useEffect(() => {
    fetch.error && datadogRum.addError(fetch.error);
  }, [fetch.error]);

  return useFollowInteractionRedirect({
    authToken: token ?? "",
    location: fetch?.data?.location ?? "",
    skip: !!skip,
  });
};

export type UseSubmitConsentPromptProps = {
  interactionId?: string;
  skip?: boolean;
};
export const useSubmitConsentPrompt = ({
  interactionId,
  skip,
}: UseSubmitConsentPromptProps) => {
  const { token, logOut } = useFirebaseAuthCheck();
  /**
   * Problem: there does not seem to be a way to partially follow redirects,
   * meaning we cannot follow the redirect /auth/:id but *not* the next redirect
   * to plaid
   *
   * Perhaps we can return the code in a JSON 200 and perform the redirect client
   * side
   */

  const fetch = useFetch<OIDCPostInteractionResponse | undefined>(
    formatPath(`/oidc/interaction/${interactionId}/consent`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ?? "",
      },
      credentials: "include",
      redirect: "error",
      body: JSON.stringify({}),
      depends: [token, interactionId, !skip],
      formatter: async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return OIDCPostInteractionResponseSchema.parse(data);
        } else {
          const safeParse = OIDCPostInteractionErrorSchema.safeParse(data);
          if (
            safeParse.success &&
            (safeParse.data.message === "MFA required" ||
              safeParse.data.message === "Unauthorized Access")
          ) {
            // If we got an auth related error, logout will cause everything to restart
            // again
            await logOut();
          }
        }
      },
    },
  );

  if (fetch.error) {
    throw fetch.error.cause;
  }

  useEffect(() => {
    fetch.error && datadogRum.addError(fetch.error);
  }, [fetch.error]);

  return useFollowInteractionRedirect({
    authToken: token ?? "",
    location: fetch?.data?.location ?? "",
    skip: skip || !fetch?.data?.location,
  });
};

export type UseDenyConsentPromptProps = {
  interactionId?: string;
  skip?: boolean;
};
export const useDenyConsentPrompt = ({
  interactionId,
  skip,
}: UseDenyConsentPromptProps) => {
  const { token, logOut } = useFirebaseAuthCheck();

  const fetch = useFetch<OIDCPostInteractionResponse | undefined>(
    formatPath(`/oidc/interaction/${interactionId}/deny`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ?? "",
      },
      credentials: "include",
      redirect: "error",
      body: JSON.stringify({}),
      depends: [token, interactionId, !skip],
      formatter: async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return OIDCPostInteractionResponseSchema.parse(data);
        } else {
          const safeParse = OIDCPostInteractionErrorSchema.safeParse(data);
          if (
            safeParse.success &&
            (safeParse.data.message === "MFA required" ||
              safeParse.data.message === "Unauthorized Access")
          ) {
            // If we got an auth related error, logout will cause everything to restart
            // again
            await logOut();
          }
        }
      },
    },
  );

  useEffect(() => {
    fetch.error && datadogRum.addError(fetch.error);
  }, [fetch.error]);

  return useFollowInteractionRedirect({
    authToken: token ?? "",
    location: fetch?.data?.location ?? "",
    skip: skip || !fetch?.data?.location,
  });
};

type UseFollowInteractionRedirectProps = {
  authToken: string;
  skip: boolean;
  location: string;
};

export type UseFollowInteractionRedirectResult =
  | {
      type: "nextInteraction";
      next: OIDCInteractionResponse;
    }
  | {
      type: "codeRedirect";
      redirectTo: string;
    };
const useFollowInteractionRedirect = ({
  authToken,
  location,
  skip,
}: UseFollowInteractionRedirectProps): FetchResult<
  UseFollowInteractionRedirectResult | undefined
> => {
  const [nextLocation, setNextLocation] = useState<string | undefined>();

  const computeLocation = useMemo(
    () => nextLocation ?? location,
    [location, nextLocation],
  );

  const fetchResult = useFetch<UseFollowInteractionRedirectResult | undefined>(
    computeLocation,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: authToken ?? "",
      },
      credentials: "include",
      depends: [!!authToken, !skip, computeLocation],
      formatter: async (response) => {
        if (response.status === 200) {
          const body = await response.json();

          const maybeRedirect =
            OIDCPostInteractionResponseSchema.safeParse(body);

          const maybeDate = OIDCInteractionResponseSchema.safeParse(body);

          if (maybeRedirect.success) {
            const redirectTo = maybeRedirect.data.location;
            if (redirectTo.startsWith("/")) {
              setNextLocation(formatPath(redirectTo));
            } else {
              return {
                type: "codeRedirect",
                redirectTo,
              };
            }
          } else if (maybeDate.success) {
            return {
              type: "nextInteraction",
              next: maybeDate.data,
            };
          } else {
            throw new Error(
              `Unexpected response status ${response.status} ${response.statusText}`,
            );
          }
        } else {
          throw new Error(
            `Unexpected response status ${response.status} ${response.statusText}`,
          );
        }
      },
    },
  );

  useEffect(() => {
    fetchResult.error && datadogRum.addError(fetchResult.error);
  }, [fetchResult.error]);

  return fetchResult;
};
